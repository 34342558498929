import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import { Link } from "gatsby"
import Particle6 from "../components/Particle6"
import SEO from "../components/SEO"

const special_proj = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Web-dev Projects"
          description="This Page is for Web-dev cool projects ."
        />
        <Particle6></Particle6>
        <h6>s</h6>
        <div className="programming-project">
          <h1>The Special Project</h1>
        </div>

        <div className="special noSelect">
        </div>
        <section className="website noSelect">
          <p>
            Well, The Special Project is the one you are looking at.
            <br />
            Ofcourse, this website.
            <br />
            How can i forget to talk about it ?<br />
            <br />
            So, I started this website as a project under web-dev.
            <br />
            Lets talk about some features and skills behind it:
            <br />
            <br />={">"} The website is developed by GatsbyJS. <br />={">"} You
            can easily feel the dynamic nature of this website.
            <br />={">"} Query done through 'GraphQL'.
            <br />={">"} This website is SEO Optimised.
            <br />={">"} Amazing background interaction using ParticleJS.
            <br />={">"} I have also used twitter cards.
            <br />={">"} And a lot of cool plugins are involved...
            <br />={">"} Then finally... Deployed on super-easy Netlify.
            <br />
            <br />
            Required skills: JavaScript, CSS, GatsbyJS, GraphQL, TwitterCards,
            Helmet, SEO, ParticleJS, Netlify,
            <br />
            (and some other plugins as per required.)
            <br />
            <br />
            As, this is the only aim, why I explore web-dev, so I am not looking
            to gain and add any extra skill further, for now...
            <br />
            As, you all know... there is always way more than you think ...
            <br />
            <br /> But what about my existing skills of web-dev...
            <br />
            Well I have always a plan for my things...
            <br />
            <br />
            As a beginner, I have a damm lot of future ideas with this website.
            <br />
            Let me mention some...
            <br />
            <br />={">"} The Quote box, on my About page,
            <br />
            I want to upgrade this box, with an amazing feature.
            <br />
            I want to add my own voice for each quote, it would be an excellent
            interaction with you all.
            <br />
            As a human voice gives the actual senses behind the words...
            <br />
            Still, it can be time consuming, but amazing too...
            <br />
            <br />={">"} On my project page, as my number of projects get
            increase, I need to do some indexing, <br />
            and moreover it would be great with a bookmark feature with
            collapsing and categorised arrangement.
            <br />
            <br />={">"} The top title (text) on each page, I want to change the
            text with a stylish and dashing look using particleJs.
            <br />
            <br />={">"} For the backend API, I want to use a headless CMS -
            'Contentful' or 'Strapi' for my website.
            <br />
            I am almost done with it, I will configure them very soon.
            <br />
            <br />={">"} As, you can see I have used 'Dark Mode' for my website.
            Well yes, I am a programmer, but moreover I like it.
            <br />
            So I want to add a switching button between dark mode and light
            mode.
            <br />
            (It's also a very fast growing feature in websites and apps, if you
            noticed).
            <br />
            <br />
            And so on...
            <br />
            <br />
            So like these cool ideas... I have nearly 1-2 dozens of ideas with
            this website...
            <br />
            And with each passing time the ideas are increasing...
            <br />
            <br />
            Well, for now I have no clue, about how I am going to complete many
            of my ideas.
            <br />
            But "Finding A Way" is what makes a human life intresting.
            <br />
            <br />
            So, these future upgrade ideas will help me keep my skills fresh and
            sharp over a long period of time.
            <br />
            That's what is needed now days...
          </p>
        </section>
      </Layout>
    </div>
  )
}

export default special_proj
